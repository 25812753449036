<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import VaccintypesData from "./vaccins-data";
import AddVaccintype from "./add-vaccin";

import {
  layoutComputed,
  authComputed,
  vaccintypesMethods,
  vaccintypesComputed,
} from "@/state/helpers";
/**
 * Vaccintypes component
 */
export default {
  page: {
    title: "Vaccintypes",
    meta: [{ name: "Vaccintypes" }],
  },
  components: {
    Layout,
    PageHeader,
    VaccintypesData,
    AddVaccintype,
  },
  mounted() {
    this.retrieveVaccintypes();
  },
  data() {
    return {
      title: "titles.vaccintypes.text",
      busy: false,

      loaderDashboard: false,

      vaccintypesData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...vaccintypesComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.vaccintypes.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...vaccintypesMethods,

    retrieveVaccintypes() {
      if (this.getVaccintypes.results) {
        this.vaccintypesData = this.getVaccintypes.results;
      } else {
        this.busy = true;
        this.getVaccintypesBy({ limit: 100 }).then(() => {
          this.busy = false;
        });
      }
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-vaccintype-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-vaccintype-modal");
    },
    add(vaccintype) {
      this.addVaccintype(vaccintype)
        .then((vaccintype) => {
          this.makeToast("Add vaccintype", vaccintype.name+" has been added", "success")
        })
        .catch(error => {
          this.makeToast("Add vaccintype", error, "danger")
        });
    },
  },
  watch: {
    getVaccintypes(newVal) {
      this.vaccintypesData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addVaccintype.text")}}
        </b-button>
        <AddVaccintype @addVaccintype="add" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <VaccintypesData v-bind:vaccintypes="vaccintypesData" :busy="busy" />
      </div>
    </div>
  </Layout>
</template>